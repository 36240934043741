var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Calendar Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'calendar_name',
            {
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'calendar_name',\n            {\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Payment Cycle"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'payment_cycle',
            {
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'payment_cycle',\n            {\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}],attrs:{"default-value":"monthly"}},[_c('a-select-option',{attrs:{"value":"monthly"}},[_vm._v(" Monthly ")]),_c('a-select-option',{attrs:{"value":"weekly","disabled":""}},[_vm._v(" Weekly ")]),_c('a-select-option',{attrs:{"value":"hourly","disabled":""}},[_vm._v(" Hourly ")])],1)],1),_c('a-form-item',{attrs:{"label":"Reviewer"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'reviewer',
            {
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'reviewer',\n            {\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}]},_vm._l((_vm.admins),function(reviewer){return _c('a-select-option',{key:reviewer.id,attrs:{"value":reviewer.email}},[_vm._v(_vm._s(reviewer.email))])}),1)],1),_c('a-form-item',{attrs:{"label":"Normal Payday"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'date',
            {
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'date',\n            {\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }