var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[void 0,_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Deduction Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Department"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'departments',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'departments',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}],attrs:{"mode":"multiple"},on:{"change":_vm.handleChange}},_vm._l((_vm.client.departments),function(dep){return _c('a-select-option',{key:dep.id,attrs:{"value":dep.department_name}},[_vm._v(" "+_vm._s(dep.department_name))])}),1)],1),_c('a-form-item',{attrs:{"label":"Frequency"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'frequency',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'frequency',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}],attrs:{"name":"radioGroup","default-value":1}},[_c('a-radio',{attrs:{"value":"oneoff"}},[_vm._v(" One off ")]),_c('a-radio',{attrs:{"value":"monthly"}},[_vm._v(" Monthly")])],1)],1),_c('a-form-item',{attrs:{"label":"Amount"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'amount',
            { rules: [{ required: true, message: 'Field is required!' }] } ]),expression:"[\n            'amount',\n            { rules: [{ required: true, message: 'Field is required!' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v(" Submit ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }