var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Company Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_name',
            {
              initialValue: _vm.client.company_name,
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'company_name',\n            {\n              initialValue: client.company_name,\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"KRA PIN"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'kra_pin',
            {
              initialValue: _vm.client.kra_pin,
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'kra_pin',\n            {\n              initialValue: client.kra_pin,\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}],attrs:{"placholder":"KRA PIN"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"NHIF NUMBER"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'nhif_number',
            {
              initialValue: _vm.client.nhif_number,
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'nhif_number',\n            {\n              initialValue: client.nhif_number,\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}],attrs:{"placholder":"KRA PIN"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"NSSF NUMBER"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'nssf_number',
            {
              initialValue: _vm.client.nssf_number,
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'nssf_number',\n            {\n              initialValue: client.nssf_number,\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}],attrs:{"placholder":""}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Company Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_email',
            {
              initialValue: _vm.client.company_email,
              rules: [{
            type: 'email',
            message: 'The input is not valid E-mail!',
          },{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'company_email',\n            {\n              initialValue: client.company_email,\n              rules: [{\n            type: 'email',\n            message: 'The input is not valid E-mail!',\n          },{ required: true, message: 'Field is required!' }],\n            },\n          ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_phone',
            {
              initialValue: _vm.client.company_phone,
              rules: [
                { required: true, message: 'Please input phone number!' } ],
            } ]),expression:"[\n            'company_phone',\n            {\n              initialValue: client.company_phone,\n              rules: [\n                { required: true, message: 'Please input phone number!' },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['prefix', { initialValue: '+254' }]),expression:"['prefix', { initialValue: '+254' }]"}],staticStyle:{"width":"70px"},attrs:{"slot":"addonBefore"},slot:"addonBefore"},[_c('a-select-option',{attrs:{"value":"+254"}},[_vm._v(" +254 ")]),_c('a-select-option',{attrs:{"value":"+1"}},[_vm._v(" +1 ")])],1)],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Bank Name"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_name',
            {
              initialValue: _vm.client.bank_name,
              rules: [
                { required: true, message: 'Please choose the bank' } ],
            } ]),expression:"[\n            'bank_name',\n            {\n              initialValue: client.bank_name,\n              rules: [\n                { required: true, message: 'Please choose the bank' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Please choose the bank"},on:{"change":_vm.handleBankChange}},_vm._l((_vm.bankNames),function(bank){return _c('a-select-option',{key:bank,attrs:{"value":bank}},[_vm._v(_vm._s(bank)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Branch Name"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'bank_branch',
            {
              initialValue: _vm.client.bank_branch,
              rules: [{ required: true, message: 'please enter branch' }],
            } ]),expression:"[\n            'bank_branch',\n            {\n              initialValue: client.bank_branch,\n              rules: [{ required: true, message: 'please enter branch' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Branch Name"}},_vm._l((_vm.branches),function(branch){return _c('a-select-option',{key:branch.id,attrs:{"value":branch.BranchName}},[_vm._v(_vm._s(branch.BranchName)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Account Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'account_name',
            {
              initialValue: _vm.client.account_name,
              rules: [{ required: true, message: 'please enter account' }],
            } ]),expression:"[\n            'account_name',\n            {\n              initialValue: client.account_name,\n              rules: [{ required: true, message: 'please enter account' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"account Name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Account Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'account_number',
            {
              initialValue: _vm.client.account_number,
              rules: [
                {
                  required: true,
                  message: 'please enter account number',
                } ],
            } ]),expression:"[\n            'account_number',\n            {\n              initialValue: client.account_number,\n              rules: [\n                {\n                  required: true,\n                  message: 'please enter account number',\n                },\n              ],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"account number","type":"number"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Company Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_address',
            {
              initialValue: _vm.client.address,
              rules: [{ required: true, message: 'Field is required!' }],
            } ]),expression:"[\n            'company_address',\n            {\n              initialValue: client.address,\n              rules: [{ required: true, message: 'Field is required!' }],\n            },\n          ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"Company Logo"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'logo',
            { rules: [{ required: false, message: 'Field is required!' }] } ]),expression:"[\n            'logo',\n            { rules: [{ required: false, message: 'Field is required!' }] },\n          ]"}],attrs:{"name":"file","multiple":false,"list-type":"picture","transform-file":_vm.transformFile,"file-list":_vm.fileList,"remove":_vm.handleRemove,"before-upload":_vm.beforeUpload}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" Click to Upload logo")],1)],1)],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading,"block":""}},[_vm._v(" Edit Details ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }