var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h6',[_vm._v("Departments")])]),_c('a-col',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end"},attrs:{"span":24,"md":12}},[_c('a-radio-group',{attrs:{"size":"small"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.openModal}},[_vm._v(" ADD DEPARTMENTS ")])],1),_c('a-radio-group',{staticClass:"mx-5",attrs:{"size":"small"}},[_c('a-button',{on:{"click":_vm.showDrawer}},[_vm._v("ADD DESIGNATIONS")])],1)],1)],1)]},proxy:true}])},[_c('div',[_c('a-modal',{attrs:{"title":"Create Department","on-ok":"handleSubmit"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" Submit ")])],1),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":" Select Department","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'departments',
              {
                rules: [{ required: true, message: 'Field is required!' }],
              } ]),expression:"[\n              'departments',\n              {\n                rules: [{ required: true, message: 'Field is required!' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"tags","placeholder":"Type and enter or search to select"}},_vm._l((_vm.departments),function(department){return _c('a-select-option',{key:department},[_vm._v(" "+_vm._s(department)+" ")])}),1)],1)],1)],2)],1),_c('div',[_c('a-modal',{attrs:{"title":"Create Designation","on-ok":"handleSubmit"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submitDesignation}},[_vm._v(" Submit ")])],1),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":false},on:{"submit":function($event){$event.preventDefault();return _vm.submitDesignation.apply(null, arguments)}}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Type or Select Department","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'department',
                  {
                    rules: [
                      { required: false, message: 'Field is required!' } ],
                  } ]),expression:"[\n                  'department',\n                  {\n                    rules: [\n                      { required: false, message: 'Field is required!' },\n                    ],\n                  },\n                ]"}],on:{"change":_vm.handleDeptChange}},_vm._l((_vm.client.departments),function(dept){return _c('a-select-option',{key:dept.department_name},[_vm._v(" "+_vm._s(dept.department_name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Designations","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'designations',
                  {
                    rules: [
                      { required: false, message: 'Field is required!' } ],
                  } ]),expression:"[\n                  'designations',\n                  {\n                    rules: [\n                      { required: false, message: 'Field is required!' },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"tags","placeholder":"Search to select"}},_vm._l((_vm.designations),function(designation){return _c('a-select-option',{key:designation.name},[_vm._v(" "+_vm._s(designation.name)+" ")])}),1)],1)],1)],1)],1)],2)],1),(_vm.client.departments)?_c('a-row',{attrs:{"type":"flex"}},_vm._l((_vm.client.departments),function(department){return _c('a-col',{key:department.department_name,attrs:{"span":2}},[_c('a-tag',{staticStyle:{"height":"50px","display":"flex","align-items":"center","width":"100px","justify-content":"center"}},[_vm._v(_vm._s(department.department_name))])],1)}),1):_c('a-empty')],1)}
var staticRenderFns = []

export { render, staticRenderFns }